$(document).ready(function() {
	
	// Endpoint
	url = 'https://api.lever.co/v0/postings/LoyaltyLion?group=team&mode=json';

	//Checking for potential Lever source or origin parameters
	var pageUrl = window.location.href;
	var leverParameter = '';
	var trackingPrefix = '?lever-';

	if( pageUrl.indexOf(trackingPrefix) >= 0){
		// Found Lever parameter
		var pageUrlSplit = pageUrl.split(trackingPrefix);
		leverParameter = '?lever-'+pageUrlSplit[1];
	}

	//Functions for checking if the variable is unspecified
	function cleanString(string) {
		if (string) {
			var cleanString = string.replace(/\s+/ig, "");
			return cleanString;
		}
		else {
			return "Uncategorized";
		}
	}

	function nullCheck(string) {
		if (!string) {
			var result = 'Uncategorized';
			return result;
		}
		else { 
			return string;
		}
	}

	function createJobs(_data) {
		for(i = 0; i < _data.length; i++) {
			
			var team = nullCheck(_data[i].title);

			var teamCleanString = cleanString(team);
			$('#js-lever-jobs-container .lever-jobs__teams ul').append(
				'<li class="'+teamCleanString+'">'+team+'</li>'
			);
		}

		for(i = 0; i < _data.length; i++) {
			for (j = 0; j < _data[i].postings.length; j ++) {
				var posting = _data[i].postings[j];
				var title = posting.text;
				var description = posting.descriptionPlain;
				var shortDescription = $.trim(description).substring(0, 130).replace('\n', ' ') + "...";
				var location = nullCheck(posting.categories.location);
				var locationCleanString = cleanString(location);
				var commitment = nullCheck(posting.categories.commitment);
				var commitmentCleanString = cleanString(commitment);
				var team = nullCheck(posting.categories.team);
				var teamCleanString = cleanString(team);
				var link = posting.hostedUrl+leverParameter;
				
					$('#js-lever-jobs-container .lever-jobs__listing').append(
						'<div class="lever-jobs__item '+teamCleanString+' '+locationCleanString+' '+commitmentCleanString+'">' +
							'<a class="lever-jobs__item-title" href="'+link+'" target="_blank" rel="noopener noreferrer">'+title+'</a>' +
							'<p class="lever-jobs__item-description">'+shortDescription+'</p>' +
							'<div class="lever-jobs__item-footer">' +
								'<a class="lever-jobs__item-footer-more arrow-right" href="'+link+'" target="_blank" rel="noopener noreferrer">Read more</a>' +
								'<div class="lever-jobs__item-footer-location">'+location+'</div>' +
							'</div>' +
						'</div>'
				
				);
			}
		}
	}

	//Creating filter buttons for sorting your jobs
	function activateButtons(_data){
		$('.lever-jobs__teams').on("click", "li", function(e) {
			for(i = 0; i < _data.length; i++) {
				var teamRaw = _data[i].title;
				var team = cleanString(teamRaw);
				var jobs = $(".lever-jobs__listing");

				if ($(this).hasClass("clear-filters")) {// 'All' filter
					$(".lever-jobs__teams").find("li").removeClass("active");
					$(this).addClass("active");
					jobs.find(".lever-jobs__item").fadeIn("fast");
				}

				if ($(this).hasClass(team)) {
					if ($(this).hasClass("active")) { 
						$(this).removeClass("active");
						jobs.find(".lever-jobs__item").fadeIn("fast");
					} else {
						$(".lever-jobs__teams").find("li").removeClass("active");
						$(this).addClass("active");
						jobs.find("."+team).fadeIn("fast");
						jobs.find(".lever-jobs__item").not("."+team).fadeOut("fast");
					}
				}
			}
		});
	}

	//Fetching job postings from Lever's postings API
	$.ajax({
		dataType: "json",
		url: url,
		success: function(data){
			createJobs(data);
			activateButtons(data);
		}
	});

});