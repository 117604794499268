$(document).ready(function() {
  // Blog filtering
  var $grid = $('.section__blog__archive__cards .row').isotope({
    // options
  })

  // Blog click making new blog section active
  $('.section__blog__archive__sort').on('click', 'li', function() {
    var filterValue = $(this).attr('data-filter')
    $grid.isotope({ filter: filterValue })
    $('.section__blog__archive__sort .active').removeClass('active')
    $(this).addClass('active')
  })
})
