$(document).ready(function () {
	/*
	* FAQ block
	*/
	if(document.querySelector('.faq-accordion')){
		document.querySelectorAll('.faq-accordion').forEach(accordion => {
			accordion.querySelectorAll('.faq-accordion__item').forEach(accordionTab => {
				let trigger = accordionTab.querySelector('.faq-accordion__item-title');

				if(trigger){
					trigger.addEventListener('click', function(e){
						e.preventDefault();
						accordionTab.classList.contains('active') ? accordionTab.classList.remove('active') : accordionTab.classList.add('active')
					})
				} 
			});
		});
	}
	
})