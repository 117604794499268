/*
* Animates increasing number values
* 
*/

document.addEventListener('DOMContentLoaded', function() {
    const statSpans = document.querySelectorAll('.data-stat__stat');

    function animateNumbers(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const numberSpans = entry.target.querySelectorAll('.number-to-animate');
                numberSpans.forEach(span => {
                    const finalValue = parseFloat(span.parentElement.getAttribute('data-value'));
                    const duration = 2500;
                    const start = 0;
                    let startTime = null;

                    function step(timestamp) {
                        if (!startTime) startTime = timestamp;
                        const progress = timestamp - startTime;
                        span.textContent = Math.floor((progress / duration) * finalValue);

                        if (progress < duration) {
                            window.requestAnimationFrame(step);
                        } else {
                            span.textContent = finalValue;
                        }
                    }

                    window.requestAnimationFrame(step);
                });

                observer.unobserve(entry.target);
            }
        });
    }

    const observer = new IntersectionObserver(animateNumbers, { threshold: 0.9 });

    statSpans.forEach(span => {
        const text = span.textContent;
        const numericalValue = text.match(/[+-]?\d+(\.\d+)?/);

        if (numericalValue) {
            const extractedNumber = numericalValue[0];
            const numberSpan = document.createElement('span');
            numberSpan.className = 'number-to-animate';
            numberSpan.textContent = extractedNumber;

            span.innerHTML = text.replace(extractedNumber, numberSpan.outerHTML);
            span.setAttribute('data-value', parseFloat(extractedNumber));
            span.style.opacity = '0'; // Initially hiding the number
            setTimeout(() => {
                span.style.opacity = '1'; // Revealing the number after a delay
            }, 100);
            observer.observe(span);
        }
    });
});
