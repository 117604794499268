/*
* Integrations archive block filtering
*/
$(document).ready(function() {
	if ($('.acf-integrations-grid').length) {
		
		// Function to get query parameter value by name
		function getQueryParameter(name) {
			let urlParams = new URLSearchParams(window.location.search);
			return urlParams.get(name);
		}

		// Initialize Isotope
		var $integrations_block_grid = $('.acf-integrations-grid .integration-cards__list').isotope({
			// options
		});

		// Get the 'category' query param value
		var categoryParam = getQueryParameter('category');
		var filterValue = '*'; // Default to show all items

		if (categoryParam) {
			filterValue = '.cat--' + categoryParam;
		}

		// Trigger the filter
		$integrations_block_grid.isotope({ filter: filterValue });

		// Set the active class on the appropriate filter button
		$('.integration-cards__filters-item').each(function() {
			if ($(this).attr('data-filter') === filterValue) {
				$(this).addClass('active');
			} else {
				$(this).removeClass('active');
			}
		});

		// Scroll to the integrations grid if a category parameter is present
		if (categoryParam) {
			$('html, body').animate({
				scrollTop: $('.acf-integrations-grid').offset().top
			}, 1000); // Adjust the duration (1000ms) as needed
		}

		// Set up click event for filter buttons
		$('.integration-cards__filters-item').click(function() {
			var filterValue = $(this).attr('data-filter');
			$integrations_block_grid.isotope({ filter: filterValue });
			$('.integration-cards__filters-item').removeClass('active');
			$(this).addClass('active');
		});

	}
});
