/*
* Send user to /demo page
* gets the user's email and passes that on to the HS form on the new page
* this is shared by footer-demo-cta.php part, and loyaltylion/demo-cta block
*/

$(document).ready(function() {

	function isEmail(email) {
		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(email);
	}

	// var form = $('.block-demo-cta__form');
	// var errorMessage = $('<p class="block-demo-cta__message"></p>').text('Please enter a valid email address');

	var emailInput = $('.js-demo-cta-email');
	var submitButton = $('.js-demo-cta-submit');

	submitButton.click(function() {

		var emailAddress = emailInput.val();
		var demoUrl = 'https://loyaltylion.com/demo';

		if( isEmail(emailAddress)) {
			demoUrlWithParam = demoUrl + '?email=' + emailAddress;
			window.location.assign(demoUrlWithParam);
		} else {
			// form.after(errorMessage);// email address field is not currently required
			window.location.assign(demoUrl);
		}

	});

});