// reveal more cards on 'See more' button click
$(document).ready(function () {
    
	x=6;
	
    $('.block-media-cards__item').slice(0, 6).show();

    $('.block-media-cards__button a').on('click', function (e) {
        e.preventDefault();
        x = x+6;
        $('.block-media-cards__item').slice(0, x).slideDown();
    });
});
