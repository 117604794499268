jQuery(document).ready( function($) {	
	
	// Show the first tab content by default
	$('.feature-tabs__accordion-item:first').addClass('active');
	$('.feature-tabs__accordion-content:first').slideDown();

	// Show the first panel by default
	$('.feature-tabs__panel-item:first').addClass('active');
    
	// On tab clicks
	$('.feature-tabs__accordion-trigger').click(function() {

		// Remove active class from all items
		$('.feature-tabs__accordion-item').removeClass('active');

		// Add active class to parent of clicked tab
		$(this).parent().addClass('active');
		
		// Hide all tab content
		$('.feature-tabs__accordion-content').slideUp();

		// Hide all panels
		$('.feature-tabs__panel-item').removeClass('active');
		$('.feature-tabs__panel-item').hide();
	
		// Show the tab content div adjacent to the trigger
		$(this).next().slideDown();

		// show the panel with the same index as the tab
		var activeTabIndex = $(this).parent().attr("data-tab-index");
		var activePanel = $(".feature-tabs__panel-item[data-panel-index='" + activeTabIndex +"']");
		$(activePanel).fadeIn();
		$(activePanel).addClass('active');

	});

})