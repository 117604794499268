// docReady utility function
function docReady(fn) {//IE9+
	// see if DOM is already available
	if (document.readyState === "complete" || document.readyState === "interactive") {
		// call on next available tick
		setTimeout(fn, 1);
	} else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}

docReady(function () {//Above IE8
	if(document.querySelector('.roi-calculator')){

		/**
		 * Comma format input values / span text that have comma-format class
		 */
		document.querySelectorAll(".comma-format").forEach(function(element) {
			applyCommaFormatting(element);
		});

		function applyCommaFormatting(_obj) {
			var num;
			if (_obj.tagName === "INPUT") {
				num = getNumber(_obj.value);
				if (num == 0) {
					_obj.value = '';
				} else {
					_obj.value = num.toLocaleString('en-GB');
				}
			} else if (_obj.tagName === "SPAN") {
				num = getNumber(_obj.textContent);
				if (num == 0) {
					_obj.textContent = '';
				} else {
					_obj.textContent = num.toLocaleString('en-GB');
				}
			}

			function getNumber(_str) {
				var arr = _str.split('');
				var out = [];
				for (var cnt = 0; cnt < arr.length; cnt++) {
					if (!isNaN(arr[cnt])) {
						out.push(arr[cnt]);
					}
				}
				return Number(out.join(''));
			}
		}

		// Function to parse URL query parameters
		function getUrlParameter(name) {
			name = name.replace(/[\[\]]/g, "\\$&");
			var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
			var results = regex.exec(window.location.href);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, " "));
		}


		/**
		 * Handle the calcs
		 */
		function calculateROI(initialLoad = false) {

			// un-blur everything if there's a url param with the relevant value
			var blurElements = document.getElementsByClassName('blur');
			if (getUrlParameter("insights") && getUrlParameter("insights") == 'on') {
				for (var i = 0; i < blurElements.length; i++) {
					blurElements[i].classList.remove('blur--active');
				}
			}

			//// Input elements
			let monthlyOrdersInputElement = document.querySelector("#js-roi-orders");
			let averageOrderValueInputElement = document.querySelector("#js-roi-aov");
			let annualPurchaseFrequencyInputElement = document.querySelector("#js-roi-annual");
			let lifetimePurchasesInputElement = document.querySelector("#js-roi-lifetime");

			let gbpSwitch = document.querySelector("#roi-gbp-switch");
			let usdSwitch = document.querySelector("#roi-usd-switch");

			// If we're running on page load then check for query parameters
			if (initialLoad) {
				// Check if there are query parameters for the inputs and set their values
				if (getUrlParameter("monthly")) {
					monthlyOrdersInputElement.value = parseFloat(getUrlParameter("monthly").replace(/,/g, ''));
				}
				if (getUrlParameter("average")) {
					averageOrderValueInputElement.value = parseFloat(getUrlParameter("average").replace(/,/g, ''));
				}
				if (getUrlParameter("annual")) {
					annualPurchaseFrequencyInputElement.value = parseFloat(getUrlParameter("annual"));
				}
				if (getUrlParameter("lifetime")) {
					lifetimePurchasesInputElement.value = parseFloat(getUrlParameter("lifetime"));
				}
				if (getUrlParameter("currency") && getUrlParameter("currency") == 'gbp') {
					usdSwitch.removeAttribute("checked");
					gbpSwitch.setAttribute("checked", "checked");
				}
			}

			/**
			 * Currency switching
			 */
			let roiSwitches = document.querySelectorAll(".roi-switch");
			let selectedCurrency = 'usd';

			// Iterate through the elements to find the checked one
			for (let i = 0; i < roiSwitches.length; i++) {
				if (roiSwitches[i].checked) {
					switchId = roiSwitches[i].id;
					if(switchId == 'roi-gbp-switch'){
						selectedCurrency = 'gbp';
					}
					break; // Exit the loop once a checked radio button is found
				}
			}

			let	softwareFeeClassic = 399;
			let	softwareFeeAdvanced = 729;
			let	softwareFeePlus1 = 1650;
			let	softwareFeePlus2 = 6100;
			let	softwareFeePlus3 = 8300;
			let	softwareFeePlus4 = 12700;

			if( selectedCurrency == 'gbp' ){
				usdSwitch.removeAttribute("checked");
				gbpSwitch.setAttribute("checked", "checked");
				gbpSwitch.classList.add('is-active');
				usdSwitch.classList.remove('is-active');

				softwareFeeClassic = softwareFeeClassic * 0.79;
				softwareFeeAdvanced = softwareFeeAdvanced * 0.79;
				softwareFeePlus1 = softwareFeePlus1 * 0.79;
				softwareFeePlus2 = softwareFeePlus2 * 0.79;
				softwareFeePlus3 = softwareFeePlus3 * 0.79;
				softwareFeePlus4 = softwareFeePlus4 * 0.79;

				document.querySelectorAll('.currency-symbol').forEach(function(element) {
					element.innerHTML = '£';
				});

			} else {
				gbpSwitch.removeAttribute("checked");
				usdSwitch.setAttribute("checked", "checked");
				usdSwitch.classList.add('is-active');
				gbpSwitch.classList.remove('is-active');

				document.querySelectorAll('.currency-symbol').forEach(function(element) {
					element.innerHTML = '$';
				});

			}


			//// Input values
			let monthlyOrders = parseFloat(monthlyOrdersInputElement.value.replace(/,/g, ''));
			let averageOrderValue = parseFloat(averageOrderValueInputElement.value.replace(/,/g, ''));
			let annualPurchaseFrequency = parseFloat(annualPurchaseFrequencyInputElement.value);
			let lifetimePurchases = parseFloat(lifetimePurchasesInputElement.value);

			
			//// Output elements
			let totalRevenueOutput = document.querySelector("#js-roi-revenue");
			let returnOnInvestmentOutput = document.querySelector("#js-roi-roi");
			let newRedeemersOutput = document.querySelector("#js-roi-new-redeemers");
			let newRepeatPurchasesOutput = document.querySelector("#js-roi-new-repeats");

			// Restrcted 'Insights' elements
			let donut1 = document.querySelector("#donut-1");
			let donut2 = document.querySelector("#donut-2");
			let donut3 = document.querySelector("#donut-3");
			let referralRevenueOutput = document.querySelector("#referral-revenue");
			let shoppersCustomersOutput = document.querySelector("#cell-shoppers-cust");
			let shoppersFrequencyOutput = document.querySelector("#cell-shoppers-freq");
			let membersCustomersOutput = document.querySelector("#cell-members-cust");
			let membersFrequencyOutput = document.querySelector("#cell-members-freq");
			let redeemersCustomersOutput = document.querySelector("#cell-redeemers-cust");
			let redeemersFrequencyOutput = document.querySelector("#cell-redeemers-freq");

			//// Hardcoded figures
			let percentageDiscountReferredCustomers = 15 / 100;
			let percentageDiscountLoyaltyMembers = 5 / 100;
			let percentageOfMembersBecomingRedeemers = 0.2324;
			let percentageOfShoppersBecomingMembers = 0.3446;
			let revenueInnovativeLoyaltyReferralFeatures = 0;// no value given in spreadsheet
			let costInnovativeLoyaltyReferralFeatures = 0;// no value given in spreadsheet
			let successfullyReferredCustomersAnnually = 250;
			let percentageAnnualSpendRedeemerVersusShopper = 1.6445;
			let percentageAnnualSpendMemberVersusShopper = 0.3997;
			let percentageOrdersByMembersInvolvingReward = 0.33232649026217487;
			let percentagePurchaseFreqOfMemberAboveShopper = 0.2806;


			let costSoftwareFee = softwareFeeClassic * 12;
			if (monthlyOrders > 199999) {
				costSoftwareFee = softwareFeePlus4 * 12;
			} else if (monthlyOrders > 99999) {
				costSoftwareFee = softwareFeePlus3 * 12;
			} else if (monthlyOrders > 49999) {
				costSoftwareFee = softwareFeePlus2 * 12;
			} else if (monthlyOrders > 9999) {
				costSoftwareFee = softwareFeePlus1 * 12;
			} else if (monthlyOrders > 3999) {
				costSoftwareFee = softwareFeeAdvanced * 12;
			}

			//// Calculations
			var annualSpendAllShoppers = averageOrderValue * annualPurchaseFrequency;
			var annualSpendMember = annualSpendAllShoppers * ( 1 + percentageAnnualSpendMemberVersusShopper);
			var annualSpendRedeemingMember = annualSpendAllShoppers * ( 1 + percentageAnnualSpendRedeemerVersusShopper);
			var shoppers = (monthlyOrders / annualPurchaseFrequency ) * 12;
			var members = shoppers * percentageOfShoppersBecomingMembers;
			var newRedeemingMembersPerYear = members * percentageOfMembersBecomingRedeemers;
			var revenueCoreLoyalty = (annualSpendRedeemingMember - annualSpendMember) * newRedeemingMembersPerYear;
			var revenueCoreReferrals = successfullyReferredCustomersAnnually * averageOrderValue * lifetimePurchases;
			var costCoreLoyalty = newRedeemingMembersPerYear * annualSpendRedeemingMember * percentageOrdersByMembersInvolvingReward * percentageDiscountLoyaltyMembers;
			var costCoreReferrals = successfullyReferredCustomersAnnually * averageOrderValue * percentageDiscountReferredCustomers;
			
			var totalRevenueGenerated = revenueInnovativeLoyaltyReferralFeatures + revenueCoreLoyalty + revenueCoreReferrals;
			var newRepeatPurchasesPerYear = totalRevenueGenerated / averageOrderValue;
			var totalCosts = costInnovativeLoyaltyReferralFeatures + costCoreLoyalty + costCoreReferrals + costSoftwareFee;
			var returnOnInvestment = totalRevenueGenerated / totalCosts;

			var memberPurchaseFrequency = annualPurchaseFrequency * ( 1 + percentagePurchaseFreqOfMemberAboveShopper);	
			var redeemingMemberFrequency = annualPurchaseFrequency * ( 1 + percentageOrdersByMembersInvolvingReward);	
			
			/**
			 * Update the outputs
			*/
			totalRevenueOutput.textContent = Math.round(totalRevenueGenerated);
			returnOnInvestmentOutput.textContent = returnOnInvestment.toFixed(2);
			newRedeemersOutput.textContent = Math.round(newRedeemingMembersPerYear);
			newRepeatPurchasesOutput.textContent = Math.round(newRepeatPurchasesPerYear);

			/**
			 * Update restricted 'Insights' elements
			*/
			function updateDonut(donutElement, percentage) {
				// Set the new percentage for the donut fill
				donutElement.style.setProperty('--p', percentage);
				// Add the new percentage to the donut inner text
				const donutFigureElement = donutElement.querySelector('.donut__figure');
				if (donutFigureElement) {
					donutFigureElement.textContent = percentage + '%';
				}
			}
			updateDonut(donut1, 5.9);
			updateDonut(donut2, 23);
			updateDonut(donut3, 40);

			// Referral revenue figure
			referralRevenueOutput.textContent = revenueCoreReferrals.toFixed(2);

			// Customer snapshot table
			shoppersCustomersOutput.textContent = Math.round(shoppers);
			shoppersFrequencyOutput.textContent = annualPurchaseFrequency;
			membersCustomersOutput.textContent = Math.round(members);
			membersFrequencyOutput.textContent = memberPurchaseFrequency.toFixed(2);
			redeemersCustomersOutput.textContent = Math.round(newRedeemingMembersPerYear);
			redeemersFrequencyOutput.textContent = redeemingMemberFrequency.toFixed(2);


			// re-run comma formatting 
			document.querySelectorAll(".comma-format").forEach(function(element) {
				applyCommaFormatting(element);
			});
			
			//// log stuff
			console.group('Calculated!');
			// console.log('Software fee:', costSoftwareFee);
			// console.log('Cost core referrals:', costCoreReferrals);
			// console.log('Annual spend member:', annualSpendMember);
			console.groupEnd();
		}

		/**
		 * Run the calc
		 */
		// run on page load
		calculateROI(true);
		// run calculation when any input value changes
		document.querySelectorAll(".roi-calculator__input").forEach(function(element) {
			element.addEventListener("input", function() {
				calculateROI();
			});
		});
		// run calculation when currency switch changes
		document.querySelectorAll(".roi-switch").forEach(function(element) {
			element.addEventListener("click", function() {
				calculateROI();
			});
		});

		/**
		 * Clear all inputs
		 */
		document.querySelectorAll(".roi-calculator__clear").forEach(function(element) {
			element.addEventListener("click", function() {
				document.querySelectorAll(".roi-calculator__input").forEach(function(element) {
					element.value = '';
					calculateROI();
				});	
			});
		});


		/**
		 * Copy your results
		 */
		let calcShareButton = document.querySelector("#calculatorCopy");
		let calcShareTooltip = document.querySelector("#calculatorTooltip");

		calcShareButton.addEventListener("click", function() {
			
			// Get the current page permalink
			const permalink = calcShareButton.getAttribute("data-url");

			// Detect state of gated insights section
			const insightsState = calcShareButton.getAttribute("data-insights");
		
			// Create URL parameters for each of the four inputs + currency
			let monthlyOrders = parseFloat(document.querySelector("#js-roi-orders").value.replace(/,/g, ''));
			let averageOrderValue = parseFloat(document.querySelector("#js-roi-aov").value.replace(/,/g, ''));
			let annualPurchaseFrequency = parseFloat(document.querySelector("#js-roi-annual").value);
			let lifetimePurchases = parseFloat(document.querySelector("#js-roi-lifetime").value);

			// Create URL parameter for the currency
			let roiSwitches = document.querySelectorAll(".roi-switch");
			let selectedCurrency = 'usd';
			// Iterate through the elements to find the checked one
			for (let i = 0; i < roiSwitches.length; i++) {
				if (roiSwitches[i].checked) {
					switchId = roiSwitches[i].id;
					if(switchId == 'roi-gbp-switch'){
						selectedCurrency = 'gbp';
					}
					break; // Exit the loop once a checked radio button is found
				}
			}

			const utmParamString = '&utm_source=roi_calculator_link&utm_medium=roi_calculator_copy_link';
			const urlParams = `?monthly=${monthlyOrders}&average=${averageOrderValue}&annual=${annualPurchaseFrequency}&lifetime=${lifetimePurchases}&currency=${selectedCurrency}&insights=${insightsState}${utmParamString}`;
		
			// Combine the data-url and URL parameters to create the final URL
			const finalUrl = permalink + urlParams;
		
			// Copy the final URL to clipboard
			const textField = document.createElement('textarea');
			textField.innerText = finalUrl;
			document.body.appendChild(textField);
			textField.select();
			document.execCommand('copy');
			document.body.removeChild(textField);

			// Show the tooltip
			calcShareTooltip.style.display = 'block';
			// Hide the tooltip after 3 seconds
			setTimeout(function() {
				calcShareTooltip.style.display = 'none';
			}, 3000);
		});


		/**
		 * Insights functionality
		 */
		function checkForm() {
			
			var form = document.querySelector('.hs-form');
			
			if (form) {
				console.log('Form detected');

				// Setup a handler to run when the form passes validation
				form.addEventListener('hsvalidatedsubmit', function(e) {
					console.log('Form submitted');

					// un-blur everything
					var blurElements = document.getElementsByClassName('blur');
					for (var i = 0; i < blurElements.length; i++) {
						blurElements[i].classList.remove('blur--active');
					}

					// update data attribute for insights state on 'copy link' button
					calcShareButton.setAttribute('data-insights', 'on')
					
				});

			} else {
				setTimeout(checkForm, 1000); // Poll for the form every second
			}
		}
		checkForm();
		  
		  

	}	  
	  
});