$(document).ready(function () {
	//// Video block
	let allVideos		= document.querySelectorAll(".video");
	let allVideoIframes = document.querySelectorAll(".video__iframe");

	allVideos.forEach((video, index) => video.addEventListener("click", (event) => {
		
		if (!video.classList.contains('clicked')){
			let videoLink = video.getAttribute('data-video-link');
			console.log(videoLink);
			let videoId = videoLink.split("v=")[1].substring(0, 11)
			let videoEmbedSrc = "//www.youtube.com/embed/" + videoId;

			allVideoIframes[index].src = videoEmbedSrc + "?autoplay=1";
		}

		video.classList.add('clicked');
	}));


	//// FAQ video block specifically (class name added in block editor 'advanced' panel)
	const faqVideo = document.querySelector('.faq-video');
	const originalOffsetTop = faqVideo.offsetTop;

	window.addEventListener('scroll', function () {
		if (window.scrollY > originalOffsetTop) {
			faqVideo.classList.add('sticky');
		} else {
			faqVideo.classList.remove('sticky');
		}
	});

})