/*
* Modals from product tour cards
*/
document.addEventListener('DOMContentLoaded', function() {
	if(document.querySelector('.tour-cards')){		

		document.querySelectorAll('.tour-cards__item-button').forEach(button => {

			button.addEventListener('click', function() {
				const iframeId = this.getAttribute('data-iframe');

				// Create the modal element
				const modal = document.createElement('div');
				modal.classList.add('tour-card-modal');
				// Create inner div
				const modalInner = document.createElement('div');
				modalInner.classList.add('tour-card-modal__inner');
				// Create iframe wrapper div
				const IframeWrapper = document.createElement('div');
				IframeWrapper.classList.add('tour-card-modal__iframe');
				// Create the close X element
				const closeX = document.createElement('div');
				closeX.classList.add('tour-card-modal__close');
				// Create the iframe element
				const iframe = document.createElement('iframe');
				iframe.src = `https://capture.navattic.com/${iframeId}`;
				iframe.setAttribute('allow', 'fullscreen');
				iframe.setAttribute('data-navattic-demo-id', iframeId);

				// Add elements to the modal
				modal.appendChild(modalInner);
				modalInner.appendChild(IframeWrapper);
				IframeWrapper.appendChild(iframe);
				modalInner.appendChild(closeX);

				// Append entire modal to body
				document.body.appendChild(modal);
				// Add active class to body
				document.body.classList.add('modal-open');

				// Close modal on click outside the iframe wrapper
				modal.addEventListener('click', function(event) {
					if (!IframeWrapper.contains(event.target)) {
						modal.remove();
						document.body.classList.remove('modal-open');
					}
				});

			});

		});

	}
});
