// docReady utility function
function docReady(fn) {//IE9+
	// see if DOM is already available
	if (document.readyState === "complete" || document.readyState === "interactive") {
		// call on next available tick
		setTimeout(fn, 1);
	} else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}

//For Tabs Gutenberg block
docReady(function () {//Above IE8

	var tabBlocksArray = document.getElementsByClassName("block-tabs");
	Array.from(tabBlocksArray).forEach(function (tabBlock) {
		
		// get descendant tab items
		var navigationTabsArray = tabBlock.getElementsByClassName("block-tabs__nav-item");

		Array.from(navigationTabsArray).forEach(function (navigationTab) {
			navigationTab.addEventListener('click', function (navigationTab){
				changeTab(navigationTab);
			});
		});

	});



	const changeTab = (e) => {
		if (e) {
			let selectedTabNavItem = e.target;
			let target = selectedTabNavItem.getAttribute('data-tab-target');
			let parentBlock = selectedTabNavItem.closest('.block-tabs');
			let selectedPanel = parentBlock.querySelector(".block-tab[data-tab-index='" + target + "']");

			//1. Close panels in this block
			var allTabs = parentBlock.querySelectorAll('.block-tab');
			for (var i=0; i < allTabs.length; i++) {
				allTabs[i].setAttribute("data-tab-visible", false);
			}

			//2. Remove active tabs in this block
			var allPanels = parentBlock.querySelectorAll('.block-tabs__nav-item');
			for (var i=0; i < allPanels.length; i++) {
				allPanels[i].classList.remove('active');
			}

			//3. Make clicked tab active
			selectedTabNavItem.classList.add("active");
			//4. Open associated panel
			selectedPanel.setAttribute("data-tab-visible", true);
		}
	}

});