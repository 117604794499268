/*
* ROI range slider widget
*/
document.addEventListener('DOMContentLoaded', function() {

	if(document.getElementById('rangeSliderInput')){

		const slider = document.getElementById('rangeSliderInput');
		const ordersPanel = document.getElementById('ordersPanel');
		const ordersPanelValue = ordersPanel.querySelector('span'); 
		const revenuePanel = document.getElementById('revenuePanel');
		const revenuePanelValue = document.getElementById('revenuePanelValue');
		const thumbWidth = 16;

		// Function to set range based on rangeType toggle
		function updateRangeValues() {
			if (document.querySelector('.high-value-range')) {
				slider.min = 10000;
				slider.max = 85000;
				slider.value = 10000;
			} else {
				slider.min = 0;
				slider.max = 10000;
				slider.value = 0;
			}

			// Manually trigger the same logic as when slider is moved
			updatePanels();
		}

		// Function to handle updating panels based on slider value
		function updatePanels() {
			let rawOrdersValue = parseInt(slider.value);
			let displayOrdersValue = rawOrdersValue;

			// If the value is at the maximum, display "85k+" for high-value range
			if (rawOrdersValue == parseInt(slider.max)) {
				displayOrdersValue = (slider.max == 85000) ? '85k+' : '10k+';
			} else {
				// Format the value with commas for display
				displayOrdersValue = rawOrdersValue.toLocaleString();
			}

			// Update the orders panel value
			ordersPanelValue.textContent = displayOrdersValue;

			// Check if the orders value is 0, and explicitly set revenue to 0
			let displayRevenueValue = '0';
			if (rawOrdersValue !== 0) {
				// Hardcoded figures for revenue calculation
				var averageOrderValue = 50;
				var annualPurchaseFrequency = 2;
				var lifetimePurchases = 4;
				var percentageAnnualSpendRedeemerVersusShopper = 1.6445;
				var percentageAnnualSpendMemberVersusShopper = 0.3997;
				var percentageOfShoppersBecomingMembers = 0.3446;
				var successfullyReferredCustomersAnnually = 250;
				var percentageOfMembersBecomingRedeemers = 0.2324;

				var annualSpendAllShoppers = averageOrderValue * annualPurchaseFrequency;
				var annualSpendRedeemingMember = annualSpendAllShoppers * (1 + percentageAnnualSpendRedeemerVersusShopper);
				var annualSpendMember = annualSpendAllShoppers * (1 + percentageAnnualSpendMemberVersusShopper);
				var shoppers = (rawOrdersValue / annualPurchaseFrequency) * 12;
				var members = shoppers * percentageOfShoppersBecomingMembers;
				var newRedeemingMembersPerYear = members * percentageOfMembersBecomingRedeemers;
				var revenueCoreLoyalty = (annualSpendRedeemingMember - annualSpendMember) * newRedeemingMembersPerYear;
				var revenueCoreReferrals = successfullyReferredCustomersAnnually * averageOrderValue * lifetimePurchases;

				let rawRevenueValue = revenueCoreLoyalty + revenueCoreReferrals;
				let roundedRevenueValue = Math.round(rawRevenueValue); // Round to nearest whole number
				// Format revenue differently if over 1 million
				if (roundedRevenueValue >= 1000000) {
					displayRevenueValue = (roundedRevenueValue / 1000000).toFixed(2) + ' mil'; // Show as X.XX mil
				} else {
					displayRevenueValue = roundedRevenueValue.toLocaleString(); // Standard formatting for under 1 million
				}

				if (rawOrdersValue == parseInt(slider.max)) {
					displayRevenueValue = displayRevenueValue + '+';
				}
			}

			// Update the revenue panel value
			revenuePanelValue.textContent = displayRevenueValue;

			// Recalculate and position the order+revenue panels
			const percent = (rawOrdersValue - slider.min) / (slider.max - slider.min); 

			slider.style.setProperty('--slider-filled-width', `${percent * 100}%`);

			const sliderWidth = slider.offsetWidth;
			const ordersPanelWidth = ordersPanel.offsetWidth;
			const offsetOrdersPanel = percent * (sliderWidth - thumbWidth) - ordersPanelWidth / 2 + thumbWidth / 2;

			ordersPanel.style.left = `${offsetOrdersPanel}px`;

			const revenuePanelWidth = revenuePanel.offsetWidth;
			const offsetRevenuePanel = percent * (sliderWidth - thumbWidth) - revenuePanelWidth / 2 + thumbWidth / 2;

			revenuePanel.style.left = `${offsetRevenuePanel}px`;
		}

		// Run initially to set correct range and update the panels
		updateRangeValues();

		// Add input listener to update panels on slider movement
		slider.addEventListener('input', updatePanels);
	}
});
