$(document).ready(function() {
  $('.js-get-app-form').submit(function() {
    if (/.*\w+\.\w+/gi.test($('#install_form_url').val())) {
      document.getElementById('hubspot_user_token').value = window.__hsUserToken
      document.getElementById('page_url').value = window.location.href
      document.getElementById('page_name').value = window.document.title
    } else {
      event.preventDefault()
      flatNotify().error('Incorrect url format', 3000)
      $('#install_form_url').focus()
    }
  })
})
