jQuery(document).ready( function($) {
	
	// accordion sections found on eg. Pricing, FAQ pages
	if ($('.toggle .toggle-title').hasClass('active')) {
		$('.toggle .toggle-title.active')
		.closest('.toggle')
		.find('.toggle-inner')
		.show()
	}
	$('.toggle .toggle-title').click(function() {
		if ($(this).hasClass('active')) {
		$(this)
			.removeClass('active')
			.closest('.toggle')
			.find('.toggle-inner')
			.slideUp(200)
		} else {
		$(this)
			.addClass('active')
			.closest('.toggle')
			.find('.toggle-inner')
			.slideDown(200)
		}
	})


	// accordion block
	if ($('.accordion__item .accordion__item-title').hasClass('active')) {
		$('.accordion__item .accordion__item-title')
		.closest('.accordion__item')
		.find('.accordion__item-content')
		.show()
	}
	$('.accordion__item .accordion__item-title').click(function() {
		if ($(this).hasClass('active')) {
		$(this)
			.removeClass('active')
			.closest('.accordion__item')
			.find('.accordion__item-content')
			.slideUp(200)
		} else {
		$(this)
			.addClass('active')
			.closest('.accordion__item')
			.find('.accordion__item-content')
			.slideDown(200)
		}
	})


})