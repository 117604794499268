$('#contact-form-submit').click(function() {
  $('.input-error-note').remove()
  $('input').removeClass('input-error')

  var name = $('#contact-name')
  var email = $('#contact-email')
  var store = $('#contact-store')
  var message = $('#contact-message')

  event.preventDefault()

  function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
    return pattern.test(emailAddress)
  }

  if (name.val() === '') {
    name.addClass('input-error')
    name.after(
      '<span class="animated fadeIn input-error-note">Please enter your name</span>'
    )
    name.focus()
    return false
  }

  if (email.val() === '') {
    email.addClass('input-error')
    email.after(
      '<span class="animated fadeIn input-error-note">Please enter your email address</span>'
    )
    email.focus()
    return false
  }

  if (!isValidEmailAddress(email.val())) {
    email.addClass('input-error')
    email.after(
      '<span class="animated fadeIn input-error-note">Please check your email format</span>'
    )
    email.focus()
    return false
  }

  if (message.val() === '') {
    message.addClass('input-error')
    message.after(
      '<span class="animated fadeIn input-error-note">Please enter your message</span>'
    )
    message.focus()
    return false
  }

  if (store.val() !== '') {
    if (/.*\w+\.\w+/gi.test(store.val())) {
    } else {
      store.addClass('input-error')
      store.after(
        '<span class="animated fadeIn input-error-note">Incorrect url format</span>'
      )
      store.focus()
      return false
    }
  }

  var data = {
    name: name.val(), // required
    email: email.val(), // required
    message: message.val(), // required
    store_url: store.val(), // optional
    page_url: window.location.href,
    page_name: 'Contact - LoyaltyLion',
    hubspot_user_token: window.__hsUserToken,
  }

  console.log(data)

  $.ajax({
    type: 'POST',
    url: 'https://app.loyaltylion.com/marketing/contact-form',
    data: data,
    success: function(result) {
      $('.section__contact__hero__form').html(
        "<h3>Thank you, we'll get back to you soon!"
      )
    },
  })
})
