/*
* Customer archive block filtering
*/
$(document).ready(function() {
	if ($('.customer-archive').length) {
		
		// Function to get query parameter value by name
		function getQueryParameter(name) {
			let urlParams = new URLSearchParams(window.location.search);
			return urlParams.get(name);
		}

		// Initialize Isotope
		var $archive_block_grid = $('.customer-archive .customer-archive__list').isotope({
			// options
		});

		// Get the 'industry' query param value
		var industryParam = getQueryParameter('industry');
		var filterValue = '*'; // Default to show all items

		if (industryParam) {
			filterValue = '.industry--' + industryParam;
		}

		// Trigger the filter
		$archive_block_grid.isotope({ filter: filterValue });

		// Set the active class on the appropriate filter button
		$('.customer-archive__filters-item').each(function() {
			if ($(this).attr('data-filter') === filterValue) {
				$(this).addClass('active');
			} else {
				$(this).removeClass('active');
			}
		});

		// Set up click event for filter buttons
		$('.customer-archive__filters-item').click(function() {
			var filterValue = $(this).attr('data-filter');
			$archive_block_grid.isotope({ filter: filterValue });
			$('.customer-archive__filters-item').removeClass('active');
			$(this).addClass('active');
		});

	}
});
