
$(document).ready(function() {

	/**********************************/
	/*** STICK HEADER ***/
	/**********************************/

	// grab the initial top offset of the header
	if ($('.header').length) {
		var stickyNavTop = $('.header').offset().top

		// our function that decides weather the header bar should have "fixed" css position or not.
		var stickyNav = function() {
			var scrollTop = $(window).scrollTop() // our current vertical position from the top

			// if we've scrolled more than the header, change its position to fixed to stick to top,
			// otherwise change it back to relative
			if (scrollTop > stickyNavTop) {
				$('.header').addClass('sticky');
				$('body').addClass('header-sticky');
			} else {
				$('.header').removeClass('sticky');
				$('body').removeClass('header-sticky');
			}
		}

		stickyNav()
		// and run it again every time you scroll
		$(window).scroll(function() {
			stickyNav()
		})
	}


	
	/**********************************/
	/*** MOBILE NAVIGATION OPEN ***/
	/**********************************/
	$(document).ready(function() {
		const headerBurger = $('.header__mobile-menu-trigger');
	  
		if (headerBurger.length) {
		  headerBurger.on("click", function(event) {
			$('body').toggleClass("menu-opened");
			$('.mobile-nav__dropdown').removeClass("open");
		  });
		}
	  });
	  

	/**********************************/
	/*** MOBILE SUBMENU OPEN + CLOSE ***/
	/**********************************/
	$('.mobile-nav__item-trigger').click(function() {
		var $item = $(this).closest('.mobile-nav__item');
		var $dropdown = $item.find('.mobile-nav__dropdown');
		
		// Slide the dropdown in from the right
		$dropdown.addClass('open');
	});

	$('.mobile-nav__item-back').click(function() {
		var $item = $(this).closest('.mobile-nav__item');
		var $dropdown = $item.find('.mobile-nav__dropdown');
		
		// Slide the dropdown offscreen
		$dropdown.removeClass('open');
	});


	/**********************************/
	/*** DESKTOP CHANGE HEADER COLOUR ON SUBMENU TRIGGER HOVER ***/
	/**********************************/
	const desktopNavItems = document.querySelectorAll('.desktop-nav__item');

	// Loop through nav items
	desktopNavItems.forEach(item => {
		// Add a mouseover event listener
		item.addEventListener('mouseover', () => {
			item.classList.add('active');
		});
	
		// remove the class when the mouse leaves the element
		item.addEventListener('mouseout', () => {
			item.classList.remove('active');
		});
	});

});